import React from "react"
import "../style.css"
import Appbar from "../components/appbar"
import Card from "../components/card"
import { Helmet } from "react-helmet"

function Contact({ data }) {
  return (
    <div className="container-fluid">
      <Appbar />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact | Marcus Frolander</title>
      </Helmet>
      <div className="row">
        <div className="col-md-4 offset-md-4">
          <h2>Contact me</h2>
          <p className="muted slightly-smaller">
            Whether you have a question, proposal or just want to say hi - feel
            free to reach out!
          </p>
        </div>
        <div className="col-md-4 offset-md-4">
          <form
            name="Contact Form"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="Contact Form" />

            <div className="form-group">
              <label for="nameInput">Name</label>
              <input
                type="text"
                className="form-control"
                id="nameInput"
                name="name"
                placeholder="Your name"
              ></input>
            </div>

            <div className="form-group">
              <label for="emailInput">Email</label>
              <input
                type="email"
                class="form-control"
                id="emailInput"
                name="email"
              ></input>
            </div>

            <div className="form-group">
              <label for="messageInput">Message</label>
              <textarea
                class="form-control"
                id="messageInput"
                name="message"
                rows="3"
              ></textarea>
            </div>

            <div data-netlify-recaptcha="true"></div>

            <div className="text-right">
              <button type="submit" class="btn btn-primary">
                Send e-mail
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Contact
